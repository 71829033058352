import axiosClient from "./axiosWrapper";

export const getLogs = async (userId, signalId, messageId, date) => {
  let params = {}
  if (userId) {
    params.userId = userId
  }
  if (signalId) {
    params.signalId = signalId
  }
  if (messageId) {
    params.messageId = messageId
  }
  if (date) {
    params.dateTime = date
  }
  let result = await axiosClient().post(`/logs`, params)

  return result
}